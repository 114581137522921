<!-- 首页 -->
<template>
  <div class="email-home-page">
    <van-cell class="email-avatar">
      <van-image
        v-if="myEmailInfo"
        class="image"
        round
        width=".5rem"
        height=".5rem"
        :src="avaterUrl"
      />
      <p class="email-name">企业邮箱</p>
    </van-cell>
    <van-cell>
      <van-button round class="write-btn" @click="goEmailSend"
        >写邮件</van-button
      >
    </van-cell>
    <van-cell class="title-box">
      <p class="title">功能</p>
    </van-cell>
    <van-cell-group>
      <van-cell icon="envelop-o" title="收件箱" @click="goEmailHarvest" is-link>
        <van-badge :content="unTotal" />
      </van-cell>

      <van-cell @click="goEmailAsterisk" is-link>
        <template #title>
          <van-icon class="on" name="star" />
          <span> 星标邮件</span>
        </template>
      </van-cell>
      <van-cell icon="label-o" title="草稿箱" @click="goEmailDrafts" is-link>
      </van-cell>
      <van-cell icon="peer-pay" title="发件箱" @click="goEmailOutbox" is-link>
      </van-cell>
      <van-cell
        icon="cluster"
        @click="goEmailAddressBook"
        title="通讯录"
        is-link
      >
      </van-cell>
      <van-cell
        icon="delete-o"
        @click="goEmailRecycleBin"
        title="回收站"
        is-link
      >
      </van-cell>

      <van-cell icon="contact" @click="goEmailSetting" title="个人中心" is-link>
      </van-cell>
    </van-cell-group>
    <van-cell class="title-box">
      <p class="title">我的邮箱</p>
    </van-cell>
    <van-cell-group>
      <van-cell class="item" v-if="myEmailInfo">
        <template #title>
          <van-image
            class="image"
            round
            width=".5rem"
            height=".5rem"
            :src="avaterUrl"
          />
          <span>&nbsp;{{ myEmail }}</span>
        </template>
      </van-cell></van-cell-group
    >
  </div>
</template>

<script>
import { setEmail, getMyEmailInfo, getRecieveEmailUnRead } from "@/api/email";
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      ruleForm: {
        userName: "",
        email: "",
        password: "",
        phoneNumber: "",
      },
      rules: {
        email: [{ required: true, message: "请输入邮件名", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      isUserInfo: false, //邮箱是否同步
      unTotal: 0,
    };
  },

  components: {},

  computed: {
    ...mapState("email", ["myEmailInfo"]),
    avaterUrl() {
      if (this.myEmailInfo) {
        return this.myEmailInfo.avaterUrl;
      }
      return "";
    },
    myEmail() {
      if (this.myEmailInfo) {
        return this.myEmailInfo.email;
      }
      return "";
    },
  },

  mounted() {
    this.getUserInfo();
    this.emailInfo();
  },

  methods: {
    ...mapActions("login", ["getInfo"]),
    ...mapActions("email", ["getEmailInfo"]),
    getUserInfo() {
      this.getInfo().then((res) => {
        if (res) {
          let { data, code } = res;
          if (code == 200) {
            this.ruleForm.userName = data.userName;
            this.ruleForm.phoneNumber = data.phoneNumber;
          }
        }
      });
    },
    emailInfo() {
      this.getEmailInfo().then((res) => {
        if (res) {
          this.loading = false;
          let { data, success } = res;
          if (success) {
            if (data) {
              this.isUserInfo = true;
              this.ruleForm.email = data.email;
              this.ruleForm.password = data.password;
              this.getRecieveUnread();
            }
          }
        }
      });
    },
    getRecieveUnread() {
      getRecieveEmailUnRead().then((res) => {
        if (res) {
          let { data, code } = res;
          if (code == 200) {
            this.unTotal = data;
          }
        }
      });
    },
    goSet() {
      this.$router.push("/EmailSetting");
    },
    goEmailSend() {
      this.$router.push("/EmailSend");
    },
    goEmailHarvest() {
      this.$router.push("/EmailHarvest");
    },
    goEmailAsterisk() {
      this.$router.push("/EmailAsterisk");
    },
    goEmailDrafts() {
      this.$router.push("/EmailDrafts");
    },
    goEmailOutbox() {
      this.$router.push("/EmailOutbox");
    },

    goEmailAddressBook() {
      this.$router.push("/EmailAddressBook");
    },
    goEmailRecycleBin() {
      this.$router.push("/EmailRecycleBin");
    },
    goEmailSetting() {
      this.$router.push("/EmailSetting");
    },
  },
};
</script>
<style lang="scss" scoped>
.email-home-page {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  .email-avatar {
    ::v-deep .van-cell__value {
      display: flex;
      flex-direction: row;
    }
    .email-name {
      flex: 1;
      text-align: center;
    }
    .image {
      width: 0.5rem;
      height: 0.5rem;
    }
    .link {
      color: #0094ee;
    }
  }
  .write-btn {
    width: 100%;
    background: #0094ee;
    color: #fff;
  }
  .title-box {
    background: #f0f6ff;
    font-weight: 900;
  }
  .on {
    color: gold;
  }
  .link {
    color: #0094ee;
  }
  .item {
    .van-cell__title {
      display: flex;
      flex-direction: row;
      align-items: center;
      align-content: center;
    }
  }
}
</style>
